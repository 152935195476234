import '../App.css'
import { Icon } from '@iconify/react';

function Stack() {
    const stack = [
        { id: 1, name: "Python", icon: <Icon icon="logos:python" /> },
        { id: 2, name: "Django", icon: <Icon icon="akar-icons:django-fill" /> },
        { id: 3, name: "Django Rest Framework", icon: <Icon icon="devicon-plain:djangorest-wordmark" /> },
        { id: 4, name: "Azure Functions", icon: <Icon icon="simple-icons:azurefunctions" /> },
        { id: 5, name: "AWS EC2", icon: <Icon icon="logos:aws-ec2" /> },
        { id: 6, name: "Docker", icon: <Icon icon="skill-icons:docker" /> },
        { id: 7, name: "NGNX", icon: <Icon icon="skill-icons:nginx" /> },
        { id: 8, name: "SQL", icon: <Icon icon="vscode-icons:file-type-sql" /> },
        { id: 9, name: "Postgres", icon: <Icon icon="logos:postgresql" /> },
        { id: 10, name: "Cosmos DB", icon: <Icon icon="devicon-plain:cosmosdb-wordmark" /> },
        { id: 11, name: "SQLite", icon: <Icon icon="skill-icons:sqlite" /> },
        { id: 12, name: "HTML", icon: <Icon icon="skill-icons:html" /> },
        { id: 13, name: "CSS", icon: <Icon icon="skill-icons:css" /> },
        { id: 14, name: "JavaScript", icon: <Icon icon="skill-icons:javascript" /> },
        { id: 15, name: "React", icon: <Icon icon="skill-icons:react-dark" /> },
        { id: 16, name: "TailWind", icon: <Icon icon="skill-icons:tailwindcss-light" /> },
        { id: 17, name: "Ant Design", icon: <Icon icon="devicon:antdesign" /> },
        { id: 18, name: "Styled Component", icon: <Icon icon="skill-icons:styledcomponents" /> },
    ];
    
    return (
        <div className="stack-section">
            <h2>Stack What I've Worked With</h2>
            <div className="stack-wrapper">
                {stack.map(e => (
                    <div className="stack-element">
                        {e.icon}
                        <p>{e.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Stack;
