import NotionIntegrationProject from '../asets/projects/notionintegration.gif'
import RestaurantApiProject from '../asets/projects/APIrestaurant.png'
import DjangoBlogProject from '../asets/projects/blogdjango.png'
import PidelaCartaAppProject from '../asets/projects/pidelacartaap.gif'
import PidelaCartaLandingProject from '../asets/projects/pidelacarta.PNG'

import '../App.css'

function Portfolio() {
    const projects = [
        {
            id: 1,
            name: "App pidelacarta.com",
            url: "https://www.app.pidelacarta.com/demo",
            img: <img src={PidelaCartaAppProject} className="image" alt="pidelacarta.com" />,
            description: "A fully managed software as a service (SAAS) in production for gastronomy entrepreneurs to help them show their products and business data via QR code"
        },
        {
            id: 2,
            name: "Landing page pidelacarta.com",
            url: "https://www.pidelacarta.com/",
            img: <img src={PidelaCartaLandingProject} className="image" alt="pidelacarta.com" />,
            description: "pidelacarta.com app landing page"
        },
        {
            id: 3,
            name: "Notion Integration",
            url: "https://github.com/adolfoarmas/notionintegration",
            img: <img src={NotionIntegrationProject} className="image" alt="Notion Integration" />,
            description: "Integration with Notion API using Python."
        },
        {
            id: 4,
            name: "Restaurant Menu API",
            url: "https://adolfoarmas.pythonanywhere.com/api/schema/swager-ui",
            img: <img src={RestaurantApiProject} className="image" alt="Restaurant Menu API" />,
            description: "API for restaurant menu built in Django."
        },
        {
            id: 5,
            name: "Django Blog",
            url: "https://adolfaormas.pythonanywhere.com/",
            img: <img src={DjangoBlogProject} className="image" alt="Django Blog" />,
            description: "Blog created in Django, including user registration, authentication, and profile modification."
        }

    ]

    return (
        <div className="portfolio-wrapper">
            <h2>Side Projects</h2>
            <div className='projects-wrapper'>
                {projects.map(project => (
                    <div className="project">


                        <div className="project-link">
                            {project.img}
                            {project.name}
                            <p>{project.description}</p>
                        </div>
                        <a key={project.id}
                            href={project.url}
                            target="_blank"
                            className="css-button-sliding-to-left--green"
                            rel="noopener noreferrer">See More
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Portfolio;
