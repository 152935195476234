import '../App.css';
import { Icon } from '@iconify/react';

function Socials() {
    const socials = [
        { id: 1, title: "Send me a Whatsapp", url: "https://wa.link/5qxek7", icon: <Icon icon="ic:baseline-whatsapp" /> },
        { id: 2, title: "My Linkedin Profile", url: "https://www.linkedin.com/in/adolfoarmas/", icon: <Icon icon="brandico:linkedin-rect" /> },
        { id: 3, title: "My GitHub Profile", url: "http://www.github.com/adolfoarmas", icon: <Icon icon="codicon:github-inverted" /> },
        { id: 4, title: "My Blog in Medium", url: "https://medium.com/@adolfoarmas.90", icon: <Icon icon="basil:medium-solid" /> }
    ]
    return (
        <div className="socials-wrapper">
            {socials.map(social => (
                <a key={social.id} href={social.url} title={social.title} className="css-button-sliding-to-left--green" target="_blank" rel="noopener noreferrer">
                    {social.icon}
                </a>
            ))}
        </div>
    );
}

export default Socials;