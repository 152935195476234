import '../App.css';
import Socials from './Socials';

function Footer() {

    return (
        <footer className="footer-wrapper">
            <div className="footer-social">
                <h3>Let's connect</h3>
                <Socials />
            </div>
            <p className="copyright-text">Made with ❤️ by Adolfo</p>
        </footer>
    );
}

export default Footer;