import '../App.css';
import imgParkPow from '../asets/companies/parkpow.jpg'
import imgEdelflex from '../asets/companies/edelflex.ico'
import imgTecna from '../asets/companies/tecna.jpeg'
import imgHexagon from '../asets/companies/hexagon.jpeg'

function WorkingOn() {
  const experience = [
    {
      id: 1,
      name: "ParkPow",
      logo: imgParkPow,
      title: "Technical Support Specialist & Developer",
      since: "Jun-2023",
      description:
        <ul>
          <li>Utilize Docker and Docker Compose for efficient installation of products on client instances. </li>
          <li>Develop and execute automation scripts using Python to enable seamless information exchange between applications.</li>
          <li>Employ libraries like Flask, Pandas, and Openpyxl to enhance data flow and processing.</li>
          <li>Utilize existing CI/CD processes with GitHub Actions, ensuring smooth integration of updates and changes.</li>
          <li>Enhance web documentation using Docusaurus, a React-based framework.</li>
          <li>Innovatively integrate a Mendable AI bot, powered by a Language Model, to provide contextual assistance.</li>
          <li>Collaborate in updating and maintaining technical documentation on the website.</li>
          <li>Play a key role in customer support by providing technical solutions and assistance.</li>
        </ul>
    },
    {
      id: 2,
      name: "Edelflex",
      logo: imgEdelflex,
      title: "Developer",
      since: "Dec-2019",
      to: "Sept-2022",
    },
    {
      id: 3,
      name: "Tecna Estudios de Proyectos de Ingeniería",
      logo: imgTecna,
      title: "Industrial Automation Engineer",
      since: "Jan-2019",
      to: "Dec-2019",
    },
    {
      id: 4,
      name: "Hexagon Consulting",
      logo: imgHexagon,
      title: "Frontend Developer",
      since: "Aug-2017",
      to: "Jan-2019",
    },

  ];
  return (
    <div>
      <h2>Work Experience</h2>
      <div className="working-on-section">
        {experience.map(e => (
          <div className="working-on-wrapper">
            <div className="working-on-header">
              <div className="working-on-image-div">
                <img alt={e.name} src={e.logo} />
              </div>
              <div className="working-on-header-text">
                <p className="working-on-title">{e.title}</p>
                <p>at <b>{e.name}</b></p>
                {/* <p>since <b>{e.since}</b></p> */}
              </div>
            </div>
            {/* <div className="working-on-element-separator"></div> */}
            {/* <div className="working-on-description">{e.description}</div> */}
          </div>))
        }
        <a href="https://www.linkedin.com/in/adolfoarmas/"
          className="css-button-sliding-to-left--green"
          target="_blank"
          rel="noopener noreferrer">
          See More
        </a>
      </div>
    </div>
  );
}

export default WorkingOn;