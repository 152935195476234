import '../App.css';
import adolfoImage from '../adolfo.png';
import Socials from './Socials';

function Header() {
    return (
        <header className="App-header">
            <img src={adolfoImage} className="App-logo" alt="logo" />
            <div className="section-div">
                <h1 className="title-font">Adolfo Armas</h1>
            </div>
            <Socials />
        </header>
    )

}

export default Header;