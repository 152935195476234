import '../App.css'

function Me() {


    return (
        <div className="me">
            <h2>About Me</h2>
            <p>
                I'm a Mechatronic Engineer since 2013. <br /><br />
                I'm passionate about web development, constantly learning and ​<a className="text-link" href="https://medium.com/@adolfoarmas.90" title="I Blog in Medium" target="_blank" rel="noopener noreferrer">sharing</a> insights on technologies like
                Python, Django, React, and cloud services. I've worked as a developer across diverse sectors including pharmaceuticals,
                banking, and industry 3.0 and 4.0.
            </p>
        </div>
    );
}

export default Me;