import './App.css';
import Header from './components/Header';
import Portfolio from './components/Portfolio';
import Me from './components/Me';
import Stack from './components/Stack';
import Footer from './components/Footer';
import WorkingOn from './components/WorkingOn';

function App() {


  return (
    <div className="App-wrapper">
      <div className="top-ribbon"></div>
      <div className="App">
        <Header />
        <Me />
        <Portfolio />
        <WorkingOn />
        <Stack />
      </div>
      <Footer />
    </div >
  );
}

export default App;
